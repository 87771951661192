<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start" v-if="shelter.preview != undefined">
        <div class="mr-auto d-lg-block">
            <h2 class="text-black font-w600 mb-0">Invoice Out</h2>
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <router-link class="btn btn-outline-info mr-1" to="invoice-out" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Invoice Out
        </router-link>
        <router-link class="btn btn-outline-info mr-1" v-if="shelter.date_paid == null" :to="'create-payment-receipt?id='+$route.query.id"> <i class="fa fa-dollar"></i> Paid </router-link>
        <button class="btn btn-outline-info" @click="generatePDF"> <i class="fa fa-file-pdf-o mr-2"></i> Download Invoice </button>
    </div>
    <div class="row" v-if="shelter.preview != undefined">
        <div class="col-lg-12">
            <div class="card mt-3">
                <div class="card-header font-weight-bold"> Invoice: {{ shelter.preview.no_invoice }} <strong>{{ shelter.preview.invoice_date }}</strong> <span class="float-right">
                        <strong>Status:</strong> <span :class=" shelter.date_paid == null ? 'text-danger' : 'text-info' ">{{ shelter.date_paid == null ? 'Unpaid' : 'Paid at ' + shelter.date_paid }} </span> </span> </div>
                <div class="card-body">
                    <div class="row mb-5">
                        <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <h6>To:</h6>
                            <div> <strong> {{ shelter.preview.to.name }} </strong> </div>
                            <!-- <div>Madalinskiego 8</div> -->
                            <div>Email: {{ shelter.preview.to.email }}</div>
                            <div>Phone: {{ shelter.preview.to.phone }}</div>
                            <div>{{ shelter.preview.to.address }}</div>
                        </div>
                        <div class="mt-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right">
                            <h6>Info: </h6>
                            <div>Invoice Date: <strong>{{ shelter.preview.invoice_date }}</strong></div>
                            <div>Due Date: <strong>{{ shelter.due_date }}</strong></div>
                            <div>Back Date: <strong>{{ shelter.back_date || 'No' }}</strong></div>
                            <div>Type: <strong>{{ shelter.type }} </strong> </div>
                            <div>Approve by: <strong>{{ shelter.approve_by || 'Unapprove' }}</strong> </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="center">#</th>
                                    <th>Part Number</th>
                                    <th>Item</th>
                                    <th class="text-center">Qty</th>
                                    <th class="center">Price</th>
                                    <th class="center">Discount</th>
                                    <th class="text-right">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(value, index) in filterProduct" :key="index">
                                    <td class="center">{{ index+1 }}</td>
                                    <td class="left strong">{{ value.part_number }}</td>
                                    <td class="left">
                                        {{ value.product_name }}
                                        <span v-if="value.is_bonus == 1" class="badge badge-sm badge-info">Bonus</span>
                                    </td>
                                    <td class="text-center">{{ value.qty }}</td>
                                    <td class="center">{{ value.price.toLocaleString('id-ID') || 0 }}</td>
                                    <td class="center">{{ value.discount !== null ? value.discount.toLocaleString('id-ID') : 0 }}</td>
                                    <td class="text-right">{{ ((value.price * value.qty) - value.discount).toLocaleString('id-ID') || 0 }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-5"> </div>
                        <div class="col-lg-4 col-sm-5 ml-auto">
                            <table class="table table-clear">
                                <tbody>
                                    <tr>
                                        <td class="text-center"><strong>Subtotal</strong></td>
                                        <td class="text-right">{{ shelter.preview.sub_total.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><strong>Discount</strong></td>
                                        <td class="text-right">{{ shelter.preview.discount !== null ? shelter.preview.discount.toLocaleString('id-ID') : 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">
                                            <strong>Tax (10%) </strong>
                                            <span v-if="shelter.preview.include_tax == true" style="color: #32CD32">
                                                <i class="fa fa-lg fa-check-circle"></i>
                                            </span>
                                            <span v-else style="color: #D21404">
                                                <i class="fa fa-lg fa-times-circle"></i>
                                            </span>
                                            <strong v-if="shelter.preview.include_tax == true"> Included</strong>
                                            <strong v-else> Not Included</strong>
                                        </td>
                                        <td class="text-right">{{ shelter.preview.tax.toLocaleString('id-ID') }}</td>
                                        <!-- <td class="text-right">{{ shelter.preview.include_tax == false ? shelter.preview.tax.toLocaleString('id-ID') : '' }}</td> -->
                                    </tr>
                                    <tr>
                                        <td class="text-center"><strong>Total</strong></td>
                                        <td class="text-right"><strong>{{ shelter.preview.total.toLocaleString('id-ID') }}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm" v-if="shelter.type == 'installment'">
                                <thead>
                                    <tr>
                                        <th colspan="3" class="text-center"> Termins</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(val, i) in shelter.termin_draft" :key="i">
                                        <td class="text-center"><strong>{{ val.date_termin | moment("DD MMMM YYYY") }}</strong></td>
                                        <td class="text-center">{{ val.nominal.toLocaleString('id-ID') }}</td>
                                        <td class="text-center"> {{ val.used == 1 ? 'Unavailable' : 'Available' }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="read-content-attachment">
                        <h6>
                            <i class="fa fa-download mb-2"></i> Files
                            <span>({{shelter.files.length}})</span>
                        </h6>
                        <div class="row attachment">
                            <div class="col-auto" v-for="(val, id) in shelter.files" :key="'file' + id">
                                <a target="_blank" :href="val.file_url" class="text-muted">File {{ id + 1 }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PrintInvoice :data-invoice="shelter" ref="pdfComponent" hidden />
</div>
</template>

<script>
import axios from "axios";
import PrintInvoice from "./PrintInvoice";

export default {
    name: "DetailInvoiceOut",
    data() {
        return {
            shelter: {},
            formInput: {
                filesUpload: [],
                id: this.$route.query.id,
            },
            files: []
        };
    },
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Invoice Out | %s',
    },
    components: {
        PrintInvoice
    },
    computed: {
        filterProduct() {
            let arr = [];

            if (this.shelter.preview.products === undefined) {
                return arr;
            }

            this.shelter.preview.products.forEach(function (value, index) {
                if (index == 0) {
                    arr.push({
                        id_product: value.id_product,
                        part_number: value.part_number,
                        product_name: value.product_name,
                        price: value.price,
                        discount: value.discount,
                        is_bonus: value.is_bonus,
                        qty: 1,
                    });
                } else {
                    let found = false;

                    for (let i = 0; i < arr.length; i++) {
                        const element = arr[i];
                        if (element.part_number == value.part_number && element.is_bonus == value.is_bonus) {
                            found = true;
                            arr[i].qty += 1;
                            arr[i].discount += value.discount;
                            break;
                        }
                    }

                    if (found == false) {
                        arr.push({
                            id_product: value.id_product,
                            part_number: value.part_number,
                            product_name: value.product_name,
                            price: value.price,
                            discount: value.discount,
                            qty: 1,
                            is_bonus: value.is_bonus,
                        });
                    }
                }
            });
            return arr.sort((a, b) => a.is_bonus - b.is_bonus);
        }
    },
    async created() {
        this.onLoad()
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('invoice-out');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/invoice_out/finance/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.shelter = resp.data.data;
                }

                return this.$router.push('invoice-out');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Detail Invoice Out", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Detail Invoice Out ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async generatePDF() {
            this.$refs.pdfComponent.generateReport();
        },
        async paidNow() {

            this.formInput.filesUpload = [];

            this.$swal.fire({
                title: 'Are you sure you have paid this invoice?',
                text: "Please upload you invoice!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                input: 'file',
                inputAttributes: {
                    'multiple': 'true',
                    'v-model': "files",
                    'accept': 'image/jpeg,image/gif,image/png',
                    'aria-label': 'Upload your invoice'
                }
            }).then(async (result) => {

                if (result.isConfirmed) {
                    await result.value.forEach((element, index) => {

                        var reader = new FileReader();
                        reader.onloadend = () => {
                            this.formInput.filesUpload.push({
                                name: element.name,
                                content: reader.result
                            });

                            if (index === result.value.length - 1) {
                                this.handlePaid();
                            }
                        };
                        reader.readAsDataURL(element);
                    });

                }
            })
        },
        async handlePaid() {

            try {
                if (!this.$route.query.id) {
                    return this.$router.push('invoice-out');
                }

                await this.$store.dispatch('setLoading', true);

                setTimeout(async () => {

                    let resp = await axios.post(
                        "dental-erp-service/invoice_out/finance/update_payment", {
                            file: await this.formInput.filesUpload,
                            id: this.formInput.id
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        this.$swal.fire("Success", resp.data.message, "success");
                        this.onLoad();
                    }

                }, 2000);

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Paid Invoice", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Paid Invoice ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
