<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
        </div>
        <b-button variant="outline-info" to="payment-receipt">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Payment Receipt List
        </b-button>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 order-md-2 mb-4">
                            <h4 class="d-flex justify-content-between align-items-center mb-3">
                                <span class="text-muted">List Items</span>
                                <span class="badge badge-info badge-pill">{{ products.length }}</span>
                            </h4>
                            <ul class="list-group mb-3">
                                <li v-for="(v,i) in products" :key="i" class="list-group-item d-flex justify-content-between lh-condensed">
                                    <div>
                                        <h6 class="my-0 text-primary">{{ v.part_number }} </h6>
                                        <small class="text-muted">
                                            {{ v.product_name }} <span v-if="v.is_bonus == 1" class="badge badge-sm badge-info">Bonus</span>
                                        </small>
                                        <h6 class="text-primary">x{{ v.qty }} </h6>
                                    </div>
                                    <span class="text-muted">{{ ((v.price * v.qty) - v.discount).toLocaleString('id-ID') || 0 }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between" v-if="!formInput.include_tax">
                                    <span>Tax</span>
                                    <strong>{{ formInput.tax.toLocaleString('id-ID') }}</strong>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                    <span>Total (IDR)</span>
                                    <strong>{{ formInput.total.toLocaleString('id-ID') }}</strong>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-8 order-md-1">
                            <h4 class="mb-3">Create Payment Receipt</h4>
                            <form @submit.prevent="handleSubmit">
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="noPaymentReceipt">No Payment Receipt</label>
                                        <input type="text" class="form-control" id="noPaymentReceipt" placeholder="Enter the no payment receipt" readonly required v-model="formInput.no_receipt" autocomplete="off">
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="lastName">Invoice Out</label>
                                        <v-select label="no_invoice" :filterable="false" v-model="selected.invoice_out" :options="invoice_outs" @search="onSearchInvoiceOut" @input="setSelectedInvoiceOut">
                                            <template slot="no-options"> type to search invoice out ... </template>
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    <dl>
                                                        <dt class="text text-sm">{{option.no_invoice}}</dt>
                                                        <dd>Rp. {{ option.total.toLocaleString('id-ID') }}</dd>
                                                    </dl>
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    {{ option.no_invoice }}
                                                </div>
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 mb-3">
                                        <label for="customer">Customer</label>
                                        <input type="text" class="form-control" id="customer" readonly required v-model="formInput.customer">
                                    </div>
                                    <div class="col-md-3 mb-3">
                                        <label for="date">Date</label>
                                        <b-form-datepicker no-flip :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" locale="id" v-model="formInput.date_receipt" style="border-width: initial; height: 56px !important;"></b-form-datepicker>
                                    </div>
                                    <div class="col-md-5 mb-3">
                                        <label for="paymentAccount">Payment Account</label>
                                        <v-select label="name" :filterable="false" v-model="selected.payment_account" :options="payment_accounts" @search="onSearchPaymentAccount" @input="setSelectedPaymentAccount">
                                            <template slot="no-options"> type to search payment account ... </template>
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    <dl>
                                                        <dt class="text text-sm">{{option.account_number}}</dt>
                                                        <dd>{{ option.bank_name }} ({{ option.name }})</dd>
                                                    </dl>
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    {{ option.account_number }} - {{ option.bank_name }} ({{ option.name }})
                                                </div>
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <label for="note">Note</label>
                                        <input type="text" class="form-control" id="note" placeholder="Enter the note" v-model.trim="formInput.note">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <label for="note">File Attachment</label>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <!-- <label for="note">Files</label> -->
                                        <b-form-file v-if="!fileimage.length > 0" accept="image/jpeg,image/gif,image/png,application/pdf" multiple v-model="files"></b-form-file>
                                        <div v-else v-for="(value, index) in fileimage" :key="index">
                                            <img :src='value.file_url' width="300px" height="300px" />
                                        </div>
                                    </div>
                                </div>
                                <hr class="mb-5">
                                <button class="btn btn-success btn-lg btn-block" type="submit">
                                    <i class="fa fa-save mr-2"></i> Create Payment Receipt
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
// import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: "CreatePaymentReceipt",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create Payment Receipt | %s',
    },
    data() {
        return {
            invoice_outs: [],
            file: [],
            files: [],
            fileimage: '',
            payment_accounts: [],
            products: [],
            selected: {
                payment_account: '',
                invoice_out: '',
            },
            formInput: {
                no_receipt: '',
                id_customer: '',
                customer: '',
                id_payment_account: '',
                payment_account: '',
                id_invoice_out: '',
                total: 0,
                date_receipt: moment().format('YYYY-MM-DD'),
                note: '',
                tax: 0,
                include_tax: true
            },
        }
    },
    async created() {
        this.requestNumberReceipt()
        if (this.$route.query.id !== undefined) {
            this.setSelectedInvoiceOut({
                id: this.$route.query.id
            });
        }
    },
    watch: {
        files(newValue) {
            newValue.forEach(element => {
                var reader = new FileReader();
                reader.readAsDataURL(element);
                reader.onload = () => {
                    this.file.push({
                        content: reader.result,
                        name: element.name,
                    })
                    // this.formInput.file.content = reader.result;
                    // this.formInput.file.name = element.name;
                };
            });
        }
    },
    methods: {
        async requestNumberReceipt() {
            try {
                const params = new URLSearchParams();
                params.append('type', 'KWT');
                params.append('back_date', 0);
                // params.append('date', new Date());
                params.append('date', moment().format('YYYY-MM-DD'));

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "dental-erp-service/generate/number", {
                        params: params,
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.formInput.no_receipt = resp.data.data.number;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Number Receipt", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Number Receipt", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async onSearchInvoiceOut(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/invoice_out/finance", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.invoice_outs = await resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Invoice Out", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Invoice Out", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedInvoiceOut(val) {

            if (val == null) {
                this.formInput.id_invoice_out = '';
                this.formInput.customer = ''
                this.formInput.id_customer = ''
                this.formInput.id_invoice_out = ''
                this.formInput.total = 0
                this.formInput.tax = 0
                this.formInput.include_tax = true
                return;
            }

            try {

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/invoice_out/finance/find", {
                        id: val.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput.customer = resp.data.data.preview.to.name;
                    this.formInput.id_customer = resp.data.data.so.id_customer;
                    this.formInput.id_invoice_out = resp.data.data.id;
                    this.formInput.total = resp.data.data.total;
                    this.formInput.tax = resp.data.data.preview.tax;
                    this.formInput.include_tax = resp.data.data.preview.include_tax;
                    this.fileimage = resp.data.data.files;
                    this.selected.invoice_out = resp.data.data;

                    let arr = [];
                    resp.data.data.preview.products.forEach(function (value, index) {
                        if (index == 0) {
                            arr.push({
                                id_product: value.id_product,
                                part_number: value.part_number,
                                product_name: value.product_name,
                                price: value.price,
                                discount: value.discount,
                                is_bonus: value.is_bonus,
                                qty: 1,
                            });
                        } else {
                            let found = false;

                            for (let i = 0; i < arr.length; i++) {
                                const element = arr[i];
                                if (element.part_number == value.part_number && element.is_bonus == value.is_bonus) {
                                    found = true;
                                    arr[i].qty += 1;
                                    arr[i].discount += value.discount;
                                    break;
                                }
                            }

                            if (found == false) {
                                arr.push({
                                    id_product: value.id_product,
                                    part_number: value.part_number,
                                    product_name: value.product_name,
                                    price: value.price,
                                    discount: value.discount,
                                    qty: 1,
                                    is_bonus: value.is_bonus,
                                });
                            }
                        }
                    });

                    this.products = arr.sort((a, b) => a.is_bonus - b.is_bonus);
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Invoice Out", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Invoice Out", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async onSearchPaymentAccount(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/payment_receipt/finance/payment_account", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.payment_accounts = await resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Payment Account", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Payment Account", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedPaymentAccount(val) {

            if (val == null) {
                this.formInput.id_payment_account = '';
                return;
            }

            this.formInput.id_payment_account = val.id;
        },
        async handleAfterPaid() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/payment_receipt/finance/store",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.$router.push('payment-receipt');
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        async handleSubmit() {

            if (this.fileimage.length > 0) {
                return this.handleAfterPaid();
            }

            if (!this.formInput.no_receipt) {
                this.$swal.fire("Error", 'The No Payment Receipt field can not be empty', "error");
                return;
            }

            if (!this.formInput.id_invoice_out) {
                this.$swal.fire("Error", 'The Invoice Out field can not be empty', "error");
                return;
            }

            if (!this.formInput.date_receipt) {
                this.$swal.fire("Error", 'The Date Receipt field can not be empty', "error");
                return;
            }

            if (!this.formInput.id_payment_account) {
                this.$swal.fire("Error", 'The Payment Account field can not be empty', "error");
                return;
            }

            if (this.file.length == 0) {
                this.$swal.fire("Error", 'The Files field can not be empty', "error");
                return;
            }

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch('setLoading', true);
                        let resp = await axios.post(
                            "dental-erp-service/invoice_out/finance/update_payment", {
                                file: this.file,
                                id: this.formInput.id_invoice_out
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            // this.$swal.fire("Success", resp.data.message, "success");
                            this.handleAfterPaid();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Request Paid Invoice", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request Paid Invoice ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }

                }
            });
        },
        async readImage(e) {
            const files = e.target.files[0]
            if (!files) return;

            var reader = new FileReader();
            reader.readAsDataURL(files);
            reader.onload = () => {
                this.formInput.value = reader.result
            };
        },
    },

}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
