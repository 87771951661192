<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <b-button variant="outline-info" to="invoice-in">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Invoice In List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Invoice In</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">

                    </div> -->
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">No Purchase Order</label>
                                <v-select label="no_po" :filterable="false" v-model="selected.purchase_order_name" :options="purchase_orders" @search="onSearchPurchaseOrder" @input="setSelectedPurchaseOrder">
                                    <template slot="no-options"> type to search purchase order... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            <dl>
                                                <dt class="text text-sm text-muted">{{option.no_po}}</dt>
                                                <dd>{{option.detail.currency}} {{ option.detail.total.toLocaleString('id-ID') }}</dd>
                                            </dl>
                                            <!-- [{{ option.principle_name}}] [{{ option.part_number}}] {{ option.name }} -->
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.no_po }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">No Invoice</label>
                                <input type="text" v-model="formInput.no_invoice" class="form-control" placeholder="Enter the no invoice">
                            </div>
                            <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                <label class="text text-info">Invoice Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" locale="id" v-model="formInput.invoice_date" style="border-width: initial; height: 56px !important;"></b-form-datepicker>
                            </div>
                            <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                <label class="text text-info">Due Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" locale="id" v-model="formInput.due_date" style="border-width: initial"></b-form-datepicker>
                            </div>
                            <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                <label class="text text-info">Date Paid</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" locale="id" v-model="formInput.date_paid" style="border-width: initial"></b-form-datepicker>
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">Total</label>
                                <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model.number="formInput.total" />
                            </div>
                            <div class="form-group col-md-6 col-lg-6 col-xl-4">
                                <label class="text text-info">Upload File</label>
                                <b-form-file accept="image/jpeg,image/gif,image/png,application/pdf" multiple v-model="files"></b-form-file>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer d-sm-flex justify-content-between align-items-center">
                    <div class="card-footer-link mb-4 mb-sm-0">
                        <!-- <p class="card-text text-dark d-inline">Total Item: {{ products.length || 0 }} <br> Total Selected Item: {{ selected.length || 0 }} </p> -->
                    </div>

                    <button @click="handleSubmit" class="btn btn-outline-success"><i class="fa fa-save mr-1"></i> Create Invoice In </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: "CreateInvoiceOut",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create Invoice In | %s',
    },
    data() {
        return {
            purchase_orders: [],
            selected: {
                purchase_order_name: '',
            },
            formInput: {
                id_purchase_order: '',
                no_invoice: '',
                invoice_date: moment().format('YYYY-MM-DD'),
                due_date: moment().format('YYYY-MM-DD'),
                total: 0,
                paid: 0,
                date_paid: moment().format('YYYY-MM-DD'),
                file: []
            },
            files: [],
        };
    },
    watch: {
        files(newValue) {
            newValue.forEach(element => {
                var reader = new FileReader();
                reader.readAsDataURL(element);
                reader.onload = () => {
                    this.formInput.file.push({
                        content: reader.result,
                        name: element.name,
                    })
                    // this.formInput.file.content = reader.result;
                    // this.formInput.file.name = element.name;
                };
            });

            this.formInput.paid = newValue.length > 0 ? 1 : 0;
        }
    },
    methods: {
        async onSearchPurchaseOrder(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/invoice_in/finance/purchase_order", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.purchase_orders = await resp.data.data.filter(function (val) {
                        return val.products.length > 0
                    });
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Purchase Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Purchase Order", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedPurchaseOrder(val) {

            if (val == null) {
                this.formInput.id_purchase_order = '';
                this.selected.purchase_order_name = '';
                return;
            }

            this.formInput.id_purchase_order = val.id;
        },
        async handleSubmit() {

            if (!this.formInput.id_purchase_order) {
                this.$swal.fire("Error", 'The No Purchase Order field can not be empty', "error");
                return;
            }

            if (!this.formInput.no_invoice) {
                this.$swal.fire("Error", 'The No Invoice field can not be empty', "error");
                return;
            }

            if (!this.formInput.invoice_date) {
                this.$swal.fire("Error", 'The Invoice Date field can not be empty', "error");
                return;
            }

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "dental-erp-service/invoice_in/finance/store",
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.$router.push('invoice-in');

                            // this.purchase_orders = [];
                            // this.selected = {
                            //     purchase_order_name: '',
                            // };
                            // this.formInput = {
                            //     id_purchase_order: '',
                            //     no_invoice: '',
                            //     invoice_date: moment().format('YYYY-MM-DD'),
                            //     due_date: moment().format('YYYY-MM-DD'),
                            //     total: 0,
                            //     paid: 0,
                            //     date_paid: moment().format('YYYY-MM-DD'),
                            //     file: []
                            // };
                            // this.files = [];

                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Error ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
