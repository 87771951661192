<template>
<div class="container-fluid">
    <!-- <MenuApp /> -->
    <div class="row mt-5">
        <div class="col-lg-12 col-xl-12 col-md-12">
            <div class="row">
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Invoice.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{unpaidInvoiceOut}}</span>
                                    </h3>
                                    <p class="mb-0">Unpaid Invoice Out</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Invoice.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{unpaidInvoiceIn}}</span>
                                    </h3>
                                    <p class="mb-0">Unpaid Invoice In</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header bg-info">
                    <div>
                        <h4 class="card-title text-white">Invoice In Information</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <!-- <div class="input-group">
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div> -->
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChangeInvoiceIn" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecordsInvoiceIn" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQueryInvoiceIn.page,
                perPage: 5,
              }" :rows="rowsInvoiceIn" :columns="columnsInvoiceIn" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button title="Detail" :to="'detail-invoice-in?id='+ props.row.id" variant="outline-info" size="sm"><i class="fa fa-search"></i> Detail </b-button>
                            </div>
                            <div v-else-if="props.column.field == 'paid'">
                                <span class="btn btn-sm light" :class="props.row.paid == 1 ? 'btn-success' : 'btn-danger'">{{ props.row.paid == 1 ? 'PAID' : 'UNPAID' }}</span>
                            </div>
                            <div v-else-if="props.column.field == 'total'">
                                {{props.row.total.toLocaleString('id-ID')}}
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header d-block d-sm-flex bg-info">
                    <div>
                        <h4 class="card-title text-white">Invoice Out Information</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <!-- <div class="input-group">
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQueryPurchaseOrder.search" class="form-control w-50" placeholder="Search...">
                        </div> -->
                    </div>
                </div>
                <div style="height:100%;overflow:scroll;" class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChangeInvoiceOut" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecordsInvoiceOut" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQueryInvoiceOut.page,
                perPage: 5,
              }" :rows="rowsInvoiceOut" :columns="columnsInvoiceOut" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button title="Detail" variant="outline-info" size="sm" :to="'detail-purchase-order?id='+ props.row.id"><i class="fa fa-search"></i> Detail </b-button>
                            </div>
                            <div v-else-if="props.column.field == 'total'">
                                {{props.row.total.toLocaleString('id-ID')}}
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import MenuApp from '../../components/MenuApp';
import axios from "axios";

export default {
    name: "Dashboard",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Dashboard Finance | %s',
    },
    // components: {
    //     MenuApp
    // },
    data() {
        return {
            columnsInvoiceIn: [{
                    label: "No Invoice",
                    field: "no_invoice",
                    hidden: false,
                },
                {
                    label: "No PO",
                    field: "no_po",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Invoice Date",
                    field: "invoice_date",
                    hidden: false,
                },
                {
                    label: "Due Date",
                    field: "due_date",
                    hidden: false,
                },
                {
                    label: "Total",
                    field: "total",
                    hidden: false,
                    thClass: 'text-center',
                    tdClass: "text-center",
                    sortable: false,
                },
                {
                    label: "Paid",
                    field: "paid",
                    thClass: 'text-center',
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                // {
                //     label: "Created Date",
                //     field: "created_at",
                //     thClass: 'text-center',
                //     tdClass: "text-center",
                //     hidden: false,
                // },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            columnsInvoiceOut: [{
                    label: "No Invoice",
                    field: "no_invoice",
                    hidden: false,
                },
                {
                    label: "No SO",
                    field: "no_so",
                    hidden: false,
                    sortable: false,
                },
                // {
                //     label: "Product SO",
                //     field: "product_in_so",
                //     thClass: 'text-center',
                //     tdClass: "text-center",
                //     hidden: false,
                //     sortable: false,
                // },
                // {
                //     label: "Product DO Pending",
                //     field: "product_do_pending",
                //     thClass: 'text-center',
                //     tdClass: "text-center",
                //     hidden: false,
                //     sortable: false,
                // },
                // {
                //     label: "Product DO",
                //     field: "product_in_do",
                //     thClass: 'text-center',
                //     tdClass: "text-center",
                //     hidden: false,
                //     sortable: false,
                // },
                {
                    label: "Invoice Date",
                    field: "invoice_date",
                    hidden: false,
                },
                // {
                //     label: "Back Date",
                //     field: "back_date",
                //     hidden: false,
                // },
                {
                    label: "Due Date",
                    field: "due_date",
                    hidden: false,
                },
                // {
                //     label: "Type",
                //     field: "type",
                //     thClass: 'text-center',
                //     tdClass: "text-center",
                //     hidden: false,
                // },
                {
                    label: "Paid on",
                    field: "date_paid",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Total Amount",
                    field: "total",
                    thClass: 'text-center',
                    tdClass: "text-center",
                    hidden: false,
                },
                // {
                //     label: "Created Date",
                //     field: "created_at",
                //     thClass: 'text-center',
                //     tdClass: "text-center",
                //     hidden: false,
                // },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            totalInvoiceOut: 0,
            unpaidInvoiceOut: 0,
            unpaidInvoiceIn: 0,
            rowsInvoiceIn: [],
            rowsInvoiceOut: [],
            totalRecordsInvoiceIn: 0,
            totalRecordsInvoiceOut: 0,
            paramQueryInvoiceIn: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false,
            },
            paramQueryInvoiceOut: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false,
            },
        };
    },
    methods: {
        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.get('dental-erp-service/counting/invoice_in', {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.unpaidInvoiceIn = resp.data.data.total_invoice_unpaid;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getDataInvoiceOut() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.get('dental-erp-service/counting/invoice_out', {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.unpaidInvoiceOut = resp.data.data.total_invoice_unpaid;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getInvoiceIn() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/invoice_in/finance", this.paramQueryInvoiceIn, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecordsInvoiceIn = resp.data.pagination.total;
                    this.rowsInvoiceIn = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getInvoiceOut() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/invoice_out/finance", this.paramQueryInvoiceOut, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecordsInvoiceOut = resp.data.pagination.total;
                    this.rowsInvoiceOut = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }

        },

        updateParams(newProps) {
            this.paramQueryInvoiceIn = Object.assign({}, this.paramQueryInvoiceIn, newProps);
            this.paramQueryInvoiceOut = Object.assign({}, this.paramQueryInvoiceOut, newProps);
        },

        onPageChangeInvoiceIn(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getInvoiceIn();
        },

        onPageChangeInvoiceOut(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getInvoiceOut();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getInvoiceIn();
            this.getInvoiceOut();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getInvoiceIn();
            this.getInvoiceOut();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getInvoiceIn();
            this.getInvoiceOut();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getInvoiceIn();
            this.getInvoiceOut();
        },
    },

    async mounted() {
        await this.$store.dispatch("setApp");
        this.getData();
        this.getDataInvoiceOut();
        this.getInvoiceIn();
        this.getInvoiceOut();
    },
};
</script>

<style>
.card-information {
    box-shadow: 3px 3px 3px rgb(205, 205, 212);
    
}
</style>
