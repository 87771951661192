<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start" v-if="shelter.length != 0">
        <div class="mr-auto d-lg-block">
            <h2 class="text-black font-w600 mb-0">Invoice In</h2>
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <router-link class="btn btn-outline-info mr-1" to="invoice-in" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Invoice In
        </router-link>
    </div>
    <div class="row" v-if="shelter.po !== undefined">
        <div class="col-lg-12">
            <div class="card mt-3">
                <div class="card-header">
                    No Invoice In: {{ shelter.no_invoice }}
                    <strong>{{ shelter.invoice_date }}</strong>
                    <span class="float-right">
                        <strong>Status: </strong>
                        <span :class=" shelter.paid != 1 ? 'text-danger' : 'text-info' ">{{ shelter.date_paid == null ? 'Unpaid' : 'Paid at ' + shelter.date_paid  }} </span>
                    </span>
                </div>
                <div class="card-body">
                    <div class="row mb-5">
                        <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <h4>Info Invoice: </h4>
                            <div>Invoice Date: <strong> {{ shelter.invoice_date }} </strong> </div>
                            <div>Due Date: <strong> {{ shelter.due_date }} </strong></div>
                            <div>Paid: <strong> {{ shelter.paid != 1 ? 'Unpaid' : 'Paid at ' + shelter.date_paid }} </strong></div>
                            <!-- <div>File: <a target="_blank" :href="shelter.url_image"> Download </a></div> -->
                        </div>
                        <div class="mt-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right">
                            <h4>Info Purchase Order: </h4>
                            <div>No PO: <strong>{{ shelter.po.no_po }}</strong></div>
                            <div>Due Date: <strong>{{ shelter.po.due_date }}</strong></div>
                            <div>Currency: <strong>{{ shelter.po.detail.currency }}</strong></div>
                            <div v-if="shelter.po.detail.currency != 'IDR'">Rate: <strong>{{ shelter.po.detail.rate }}</strong></div>
                            <div>Down Payment: <strong>{{ shelter.po.detail.down_payment }}</strong></div>
                            <!-- <div>Approve by: <strong>{{ shelter.approve_by || 'Unapprove' }}</strong> </div> -->
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="center">#</th>
                                    <th>Part Number</th>
                                    <th>Item</th>
                                    <th class="center">Qty</th>
                                    <th class="center">Price</th>
                                    <th class="center">Discount</th>
                                    <th class="text-right">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(value, index) in filterProduct" :key="index">
                                    <td class="center">{{ index+1 }}</td>
                                    <td class="left strong">{{ value.part_number }}</td>
                                    <td class="left">{{ value.product_name }}</td>
                                    <td class="center">{{ value.qty }}</td>
                                    <td class="center">{{ value.price.toLocaleString('id-ID') || 0 }}</td>
                                    <td class="center">{{ value.discount.toLocaleString('id-ID') || 0 }}</td>
                                    <td class="text-right">{{ ((value.price * value.qty) - value.discount).toLocaleString('id-ID') || 0 }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-5"> </div>
                        <div class="col-lg-4 col-sm-5 ml-auto">
                            <table class="table table-clear">
                                <tbody>
                                    <tr>
                                        <td class="text-center"><strong>Subtotal</strong></td>
                                        <td class="text-right">{{ shelter.po.detail.sub_total.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><strong>Discount</strong></td>
                                        <td class="text-right">{{ shelter.po.detail.discount.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><strong>Tax (10%)</strong></td>
                                        <td class="text-right">{{ shelter.po.detail.tax.toLocaleString('id-ID') }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><strong>Total</strong></td>
                                        <td class="text-right"><strong>{{ shelter.po.detail.total.toLocaleString('id-ID') }}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="read-content-attachment">
                        <h6>
                            <i class="fa fa-download mb-2"></i> Files
                            <span>({{shelter.files.length}})</span>
                        </h6>
                        <div class="row attachment">
                            <div class="col-auto" v-for="(val, id) in shelter.files" :key="'file' + id">
                                <a target="_blank" :href="val.file_url" class="text-muted">File {{ id + 1 }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "DetailInvoiceIn",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Invoice In | %s',
    },
    data() {
        return {
            shelter: {},
        };
    },
    computed: {
        filterProduct() {
            let arr = [];

            if (this.shelter.product_in_po === undefined) {
                return arr;
            }

            this.shelter.product_in_po.forEach(function (value, index) {
                if (index == 0) {
                    arr.push({
                        id_product: value.id_product,
                        part_number: value.part_number,
                        product_name: value.product_name,
                        price: value.price,
                        discount: value.discount,
                        qty: 1,
                    });
                } else {
                    let found = false;

                    for (let i = 0; i < arr.length; i++) {
                        const element = arr[i];
                        if (element.part_number == value.part_number) {
                            found = true;
                            arr[i].qty += 1;
                            arr[i].discount += value.discount;
                            break;
                        }
                    }

                    if (found == false) {
                        arr.push({
                            id_product: value.id_product,
                            part_number: value.part_number,
                            product_name: value.product_name,
                            price: value.price,
                            discount: value.discount,
                            qty: 1
                        });
                    }
                }
            });
            return arr;
        }
    },
    async created() {
        await this.onLoad()
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('invoice-in');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/invoice_in/finance/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.shelter = resp.data.data;
                }

                return this.$router.push('invoice-in');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Detail Invoice Out", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Detail Invoice Out ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async generatePDF() {
            this.$refs.pdfComponent.generateReport();
        },
        async paidNow() {
            this.$swal.fire({
                title: 'Are you sure you have paid this invoice?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, of course!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        if (!this.$route.query.id) {
                            return this.$router.push('invoice-out');
                        }

                        await this.$store.dispatch('setLoading', true);
                        const resp = await axios.post(
                            "dental-erp-service/invoice_out/finance/update_payment", {
                                id: this.$route.query.id,
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.onLoad();
                            this.$swal.fire("Success", resp.data.message, "success");
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Request Paid Invoice", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request Paid Invoice ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            })
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
