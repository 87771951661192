<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <b-button variant="outline-info" to="invoice-out">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Invoice Out List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Invoice Out</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">

                    </div> -->
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">No Sales Order</label>
                                <v-select label="no_so" :filterable="false" v-model="selected.sales_order_name" :options="sales_orders" @search="onSearchSalesOrder" @input="setSelectedSalesOrder">
                                    <template slot="no-options"> type to search sales order... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.no_so }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.no_so }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-3" v-if="formInput.type == 'installment'">
                                <label class="text text-info">Termin Draf</label>
                                <v-select :options="termins" label="date_termin" v-model="selected.termin_name" @input="setSelectedTermin">
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            <dl>
                                                <dt class="text text-sm text-danger" v-if="option.used == 1"> {{option.date_termin}} [USED]</dt>
                                                <dt class="text text-sm text-muted" v-else> {{option.date_termin}} </dt>
                                                <dd>Nominal: {{ option.nominal.toLocaleString('id-ID') || 0 }}</dd>
                                            </dl>
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.date_termin }}, Nominal: {{ option.nominal.toLocaleString('id-ID') || 0 }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-3" v-else-if="formInput.type == 'cash'">
                                <label class="text text-info">No Delivery Order</label>
                                <v-select :options="delivery_orders" label="no_do" v-model="selected.delivery_order_name" @input="setSelectedDeliveryOrder" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">Type</label>
                                <input type="text" v-model="formInput.type" class="form-control" readonly>
                            </div>
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">No Invoice</label>
                                <input type="text" v-model="formInput.no_invoice" class="form-control" readonly placeholder="Enter the no invoice">
                            </div>
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">Payment Account</label>
                                <v-select label="name" :filterable="false" v-model="selected.payment_account" :options="payment_accounts" @search="onSearchPaymentAccount" @input="setSelectedPaymentAccount">
                                    <template slot="no-options"> type to search payment account ... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            <dl>
                                                <dt class="text text-sm">{{option.account_number}}</dt>
                                                <dd>{{ option.bank_name }} ({{ option.name }})</dd>
                                            </dl>
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.account_number }} - {{ option.bank_name }} ({{ option.name }})
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-3" v-if="formInput.type == 'installment'">
                                <label class="text text-info">Down Payment</label>
                                <div class="custom-control custom-checkbox pt-3">
                                    <input type="checkbox" class="custom-control-input" id="customCheckBox1" v-model="formInput.down_payment">
                                    <label class="custom-control-label" for="customCheckBox1"> is down payment? </label>
                                </div>
                            </div>
                            <div class="form-group col-md-4 col-lg-3">
                                <label class="text text-info">Invoice Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" locale="id" v-model="formInput.invoice_date" style="border-width: initial; height: 56px !important;"></b-form-datepicker>
                            </div>
                            <div class="form-group col-md-4 col-lg-3">
                                <label class="text text-info">Due Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" locale="id" v-model="formInput.due_date" :min="formInput.invoice_date" style="border-width: initial; height: 56px !important;"></b-form-datepicker>
                            </div>
                            <div class="form-group col-md-4 col-lg-3">
                                <label class="text text-info">Back Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" locale="id" v-model="formInput.back_date" style="border-width: initial; height: 56px !important;"></b-form-datepicker>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer d-sm-flex justify-content-between align-items-center">
                    <div class="card-footer-link mb-4 mb-sm-0">
                        <!-- <p class="card-text text-dark d-inline">Total Item: {{ products.length || 0 }} <br> Total Selected Item: {{ selected.length || 0 }} </p> -->
                    </div>

                    <button @click="handleSubmit" class="btn btn-outline-success"><i class="fa fa-save mr-1"></i> Create Invoice Out </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: "CreateInvoiceOut",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create Invoice Out | %s',
    },
    data() {
        return {
            sales_orders: [],
            delivery_orders: [],
            payment_accounts: [],
            termins: [],
            selected: {
                sales_order_name: '',
                termin_name: '',
                delivery_order_name: '',
                payment_account: ''
            },
            formInput: {
                id_payment_account: '',
                id_sales_order: '',
                no_invoice: '',
                invoice_date: moment().format('YYYY-MM-DD'),
                back_date: '',
                type: '',
                down_payment: 0,
                due_date: moment().format('YYYY-MM-DD'),
                id_invoice_termin_draft: '',
                id_delivery_order: '',
            },
        };
    },
    async created() {
        this.requestNumberINV()
    },
    methods: {
        async onSearchPaymentAccount(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/payment_receipt/finance/payment_account", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.payment_accounts = await resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Payment Account", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Payment Account", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedPaymentAccount(val) {

            if (val == null) {
                this.formInput.id_payment_account = '';
                return;
            }

            this.formInput.id_payment_account = val.id;
        },
        async requestNumberINV() {
            try {
                const params = new URLSearchParams();
                params.append('type', 'INV');
                params.append('back_date', 0);
                // params.append('date', new Date());
                params.append('date', moment().format('YYYY-MM-DD'));

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "dental-erp-service/generate/number", {
                        params: params,
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.formInput.no_invoice = resp.data.data.number;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Number SO", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Number SO", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async onSearchSalesOrder(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/invoice_out/finance/sales_order", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.sales_orders = await resp.data.data.filter(function (val) {
                        return val.products.length > 0 && val.detail !== null;
                    });
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Sales Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Order", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedSalesOrder(val) {

            this.formInput.id_invoice_termin_draft = '';
            this.formInput.id_delivery_order = '';
            this.selected.termin_name = '';
            this.selected.delivery_order_name = '';

            if (val == null) {
                this.selected.sales_order_name = '';
                this.formInput.type = '';
                // this.sales_orders = [];
                this.termins = [];
                return;
            }

            if (val.products.length == 0) {
                return this.$toastr.w(
                    'No Sales Order has been used',
                    "Error"
                );
            }

            this.formInput.id_sales_order = val.id;
            this.formInput.type = val.detail.payment_type;
            this.selected.sales_order_name = val.no_so;

            if (val.detail.payment_type == 'installment') {
                return this.getTerminDraf(val.id);
            }
            return this.getDeliveryOrder(val.id)
        },
        async getTerminDraf(id_sales_order) {
            try {
                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/invoice_out/finance/invoice_termin_draft", {
                        id_sales_order: id_sales_order,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.termins = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Termin Draf", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Termin Draf", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getDeliveryOrder(id_sales_order) {
            try {
                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/invoice_out/finance/sales_order/find", {
                        id: id_sales_order,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.delivery_orders = resp.data.data.do || [];
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Delivery Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Delivery Order", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async setSelectedTermin(val) {
            if (val == null) {
                this.selected.termin_name = '';
                this.formInput.id_invoice_termin_draft = '';
                return;
            }
            this.selected.termin_name = val;
            this.formInput.id_invoice_termin_draft = val.id;
        },
        async setSelectedDeliveryOrder(val) {
            if (val == null) {
                this.selected.delivery_order_name = '';
                this.formInput.id_delivery_order = '';
                return;
            }

            this.selected.delivery_order_name = val.no_do;
            this.formInput.id_delivery_order = val.id;
        },
        async handleSubmit() {

            if (!this.formInput.id_sales_order) {
                this.$swal.fire("Error", 'The No Sales Order field can not be empty', "error");
                return;
            }

            if (!this.formInput.no_invoice) {
                this.$swal.fire("Error", 'The No Invoice field can not be empty', "error");
                return;
            }

            if (!this.formInput.invoice_date) {
                this.$swal.fire("Error", 'The Invoice Date field can not be empty', "error");
                return;
            }

            if (!this.formInput.id_payment_account) {
                this.$swal.fire("Error", 'The Payment Account field can not be empty', "error");
                return;
            }

            if (this.formInput.type !== 'installment') {
                this.formInput.down_payment = 0;
            }

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "dental-erp-service/invoice_out/finance/store",
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.$router.push('invoice-out');

                            // this.sales_orders = [];
                            // this.delivery_orders = [];
                            // this.termins = [];
                            // this.selected = {
                            //     sales_order_name: '',
                            //     termin_name: '',
                            //     delivery_order_name: '',
                            // }

                            // this.formInput = {
                            //     id_sales_order: '',
                            //     no_invoice: '',
                            //     invoice_date: moment().format('YYYY-MM-DD'),
                            //     back_date: '',
                            //     type: '',
                            //     down_payment: 0,
                            //     due_date: moment().format('YYYY-MM-DD'),
                            //     id_invoice_termin_draft: '',
                            //     id_delivery_order: '',
                            // };
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Error ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
