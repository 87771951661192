<template>
<transition name="fade" mode="out-in" appear>
    <component :is="urlQuery"></component>
</transition>
</template>

<script>
import axios from "axios"
import Dashboard from '../views/FinanceModule/Dashboard'
import InvoiceOut from '../views/FinanceModule/InvoiceOut/InvoiceOut'
import CreateInvoiceOut from '../views/FinanceModule/InvoiceOut/CreateInvoiceOut'
import DetailInvoiceOut from '../views/FinanceModule/InvoiceOut/DetailInvoiceOut'
import PrintInvoice from '../views/FinanceModule/InvoiceOut/PrintInvoice'
import InvoiceIn from '../views/FinanceModule/InvoiceIn/InvoiceIn'
import DetailInvoiceIn from '../views/FinanceModule/InvoiceIn/DetailInvoiceIn'
import CreateInvoiceIn from '../views/FinanceModule/InvoiceIn/CreateInvoiceIn'
import PaymentReceipt from '../views/FinanceModule/PaymentReceipt/PaymentReceipt'
import CreatePaymentReceipt from '../views/FinanceModule/PaymentReceipt/CreatePaymentReceipt'
import DetailPaymentReceipt from '../views/FinanceModule/PaymentReceipt/DetailPaymentReceipt'
import PrintPaymentReceipt from '../views/FinanceModule/PaymentReceipt/PrintPaymentReceipt';

export default {
    name: 'Finance',
    components: {
        Dashboard,
        InvoiceOut,
        CreateInvoiceOut,
        DetailInvoiceOut,
        PrintInvoice,
        InvoiceIn,
        DetailInvoiceIn,
        CreateInvoiceIn,
        PaymentReceipt,
        CreatePaymentReceipt,
        DetailPaymentReceipt,
        PrintPaymentReceipt,
    },
    async created() {
        try {
            await this.$store.dispatch('setLoading', true);
            const resp = await axios.get("user-service/auth/menu?module=finance", {
                headers: {
                    Authorization: localStorage.getItem("token") || "",
                },
            });
            if (resp.data.error === false) {
                this.listApplication = resp.data.data;
                await this.$store.dispatch('setMenu', resp.data.data);
                return;
            }
        } catch (error) {
            if (error.response != undefined) {
                if (error.response.data != undefined) {
                    if (error.response.data.error_auth != undefined) {
                        this.$swal.fire("Get Application List", error.response.data.message, "error");
                        this.$store.dispatch("logOut").then(() => {
                            this.$router.push("/login");
                        });
                        return;
                    }
                    return this.$swal.fire("Get Application List ", error.response.data.message, "error");
                }
            }

            this.$sentry.captureException(error);
            this.$swal.fire("Error", error.message, "error");
        } finally {
            this.$store.dispatch("setApp");
            this.$store.dispatch('setLoading', false);
        }
    },
    computed: {
        urlQuery() {
            return this.$route.params.component || 'Dashboard';
        }
    }
}
</script>