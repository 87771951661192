<template>
<div class="container-fluid" v-if="Object.keys(shelter).length > 0">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-lg-block">
            <h2 class="text-black font-w600 mb-0">Payment Receipt</h2>
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <router-link class="btn btn-outline-info mr-1" to="payment-receipt" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Payment Receipt List
        </router-link>
        <button class="btn btn-outline-info" @click="generatePDF"> <i class="fa fa-file-pdf-o mr-2"></i> Download Payment Receipt </button>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card mt-3">
                <div class="card-header font-weight-bold"> No Receipt: {{ shelter.no_receipt }} <strong>{{ shelter.date_receipt }}</strong></div>
                <div class="card-body">
                    <div class="row mb-5">
                        <div class="mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            <h6>To:</h6>
                            <div> <strong> {{ shelter.customer.organization }} </strong> </div>
                            <!-- <div>Madalinskiego 8</div> -->
                            <div>Email: {{ shelter.customer.email }}</div>
                            <div>Phone: {{ shelter.customer.phone_number }}</div>
                            <div>{{ shelter.customer.address }}</div>
                        </div>
                        <div class="mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            <h6>Payment To:</h6>
                            <div> <strong> {{ shelter.payment_account.account_number }} </strong> </div>
                            <div> <strong> {{ shelter.payment_account.bank.name }} </strong> </div>
                            <div> <strong> {{ shelter.payment_account.name }} </strong> </div>
                            <div>{{ shelter.payment_account.branch }}</div>
                        </div>
                        <div class="mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            <h6>Info: </h6>
                            <div>Receipt Date: <strong>{{ shelter.date_receipt }}</strong></div>
                            <div>Invoice Out: <strong>{{ shelter.invoice_out.no_invoice }}</strong></div>
                            <div>Invoice Date: <strong>{{ shelter.invoice_out.invoice_date }}</strong></div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="mt-4 col-md-12">
                            <h6>Detail</h6>
                            <div> {{ shelter.paid_for }} </div>
                        </div>
                        <div class="mt-4 col-md-12">
                            <h6>Total</h6>
                            <div> IDR {{ shelter.total.toLocaleString('id-ID') }} </div>
                        </div>
                        <div class="mt-4 col-md-12">
                            <h6>Note</h6>
                            <div> {{ shelter.note }} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PrintPaymentReceipt :data-invoice="shelter" ref="pdfComponent" hidden />
</div>
</template>

<script>
import axios from "axios";
import PrintPaymentReceipt from './PrintPaymentReceipt';

export default {
    name: "DetailPaymentReceipt",
    data() {
        return {
            shelter: {},
        };
    },
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Payment Receipt | %s',
    },
    components: {
        PrintPaymentReceipt
    },
    computed: {
        filterProduct() {
            let arr = [];

            if (this.shelter.invoice_out.get_products === undefined) {
                return arr;
            }

            return arr;
            // this.shelter.invoice_out.get_products.forEach(function (value, index) {
            //     if (index == 0) {
            //         arr.push({
            //             id_product: value.id_product,
            //             part_number: value.part_number,
            //             product_name: value.product_name,
            //             price: value.price,
            //             discount: value.discount,
            //             is_bonus: value.is_bonus,
            //             qty: 1,
            //         });
            //     } else {
            //         let found = false;

            //         for (let i = 0; i < arr.length; i++) {
            //             const element = arr[i];
            //             if (element.part_number == value.part_number && element.is_bonus == value.is_bonus) {
            //                 found = true;
            //                 arr[i].qty += 1;
            //                 arr[i].discount += element.discount;
            //                 break;
            //             }
            //         }

            //         if (found == false) {
            //             arr.push({
            //                 id_product: value.id_product,
            //                 part_number: value.part_number,
            //                 product_name: value.product_name,
            //                 price: value.price,
            //                 discount: value.discount,
            //                 qty: 1,
            //                 is_bonus: value.is_bonus,
            //             });
            //         }
            //     }
            // });
            // return arr.sort((a, b) => a.is_bonus - b.is_bonus);
        }
    },
    async created() {
        this.onLoad()
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('payment-receipt');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/payment_receipt/finance/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.shelter = resp.data.data;
                }

                return this.$router.push('payment-receipt');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Detail Invoice Out", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Detail Invoice Out ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async generatePDF() {
            this.$refs.pdfComponent.generateReport();
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
